import * as React from "react";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { Link } from "gatsby";

const AboutPage = () => {
  const [hasConsented, setConsent] = React.useState(false);

  const notConsentedContent = (
    <>
      <p>
        Um den den Terminkalender anzuzeigen muss eine Verbindung zu Google
        Calendar hergestellt werden, wodurch Ihre IP und weitere Daten an Google
        weitergegeben werden. Zudem kann Google Cookies in Ihrem Browser
        speichern. Daher möchten wir Sie zunächst um Ihr Einverständnis für
        diesen Schritt durch einen Klick auf den folgenden Button bitten. Nähere
        Informationen entnehmen Sie bitte unserer{" "}
        <Link to="Datenschutz">Datenschutzerklärung</Link>.
      </p>

      <button
        className="button is-danger is-large is-fullwidth mb-4"
        onClick={() => setConsent(true)}
      >
        <span className="icon">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        <span>Zustimmen</span>
      </button>
    </>
  );

  const mobileCalendar =
    "https://calendar.google.com/calendar/b/1/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FBerlin&src=dnNnYXBjMXZiN3RlNWhtaGF1dGdpa21mdDRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=c2hnNG1ubzRmcmoza28zbGE1azQ3ZmZya3NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%2370237F&color=%23DD5511&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&mode=AGENDA&hl=de";

  const desktopCalendar =
    "https://calendar.google.com/calendar/b/1/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FBerlin&src=dnNnYXBjMXZiN3RlNWhtaGF1dGdpa21mdDRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=c2hnNG1ubzRmcmoza28zbGE1azQ3ZmZya3NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%2370237F&color=%23DD5511&showTitle=0&showPrint=0&showTabs=1&showCalendars=0&showTz=0&hl=de";

  const [isMobile, setIsMobile] = React.useState(true);

  React.useEffect(() => {
    setIsMobile(window.screen.availWidth <= 640);
  }, []);

  const iframeSrc = isMobile ? mobileCalendar : desktopCalendar;

  const consentedContent = (
    <iframe style={{ width: "100%", height: "500px" }} src={iframeSrc}></iframe>
  );

  return (
    <Layout>
      <div className="card full-width">
        <div className="card-content">
          <h1 className="title" id="title">
            Terminkalender
          </h1>

          <div className="content">
            {hasConsented ? consentedContent : notConsentedContent}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
